import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPricingPlanAlert } from "store/commonActions";
import { useHistory } from "react-router-dom";

export default function PlanAlert() {
  const show = useSelector(({ pricingPlanAlert }) => pricingPlanAlert.show);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClose = () => {
    dispatch(setPricingPlanAlert(false));
  };

  const handleViewPlan = () => {
    history.push("/pricing");
    handleClose();
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="no-active-plan"
      aria-describedby="no-active-plan-user"
    >
      <DialogTitle id="no-active-plan">No Active Plan</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Oops! It looks like you don't have an active plan. To continue
          enjoying our services, please subscribe to one of our plans. Check out
          our pricing page for more details and choose the plan that best suits
          your needs. Thank you!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleViewPlan} color="primary" autoFocus>
          View Plans
        </Button>
      </DialogActions>
    </Dialog>
  );
}
