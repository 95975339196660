import {
  OPEN_NOTIFICATION,
  CLOSE_NOTIFICATION,
  GET_RECORD_BEGIN,
  GET_RECORD_SUCCESS,
  GET_RECORD_FAILURE,
  SET_RECORD_ID,
  UPDATE_RECORD_BEGIN,
  UPDATE_RECORD_FAILURE,
  UPDATE_RECORD_SUCCESS,
  DELETE_RECORD_BEGIN,
  DELETE_RECORD_FAILURE,
  DELETE_RECORD_SUCCESS,
  DOWNLOAD_CREDENTIAL_BEGIN,
  DOWNLOAD_CREDENTIAL_FAILURE,
  DOWNLOAD_CREDENTIAL_SUCCESS,
  REVOKE_CREDENTIAL_BEGIN,
  REVOKE_CREDENTIAL_FAILURE,
  REVOKE_CREDENTIAL_SUCCESS,
  SHARE_CREDENTIAL_BEGIN,
  SHARE_CREDENTIAL_SUCCESS,
  SHARE_CREDENTIAL_FAILURE,
  GET_CREDENTIAL_LINK_BEGIN,
  GET_CREDENTIAL_LINK_SUCCESS,
  GET_CREDENTIAL_LINK_FAILURE,
  PREVIEW_DRAFT_RECORD_BEGIN,
  PREVIEW_DRAFT_RECORD_FAILURE,
  PREVIEW_DRAFT_RECORD_SUCCESS,
  LOGOUT_USER,
  TOGGLE_LOGOUT_MODAL,
  GET_CREDENTIAL_INFO_BEGIN,
  GET_CREDENTIAL_INFO_SUCCESS,
  GET_CREDENTIAL_INFO_FAILURE,
  GET_VERIFICATION_LOG_BEGIN,
  GET_VERIFICATION_LOG_SUCCESS,
  GET_VERIFICATION_LOG_FAILURE,
  GET_CREDENTIAL_GRAPH_INFO_BEGIN,
  GET_CREDENTIAL_GRAPH_INFO_FAILURE,
  GET_CREDENTIAL_GRAPH_INFO_SUCCESS,
  GET_CREDENTIAL_STATS_BEGIN,
  GET_CREDENTIAL_STATS_SUCCESS,
  GET_CREDENTIAL_STATS_FAILURE,
  SET_NEW_SOCKET,
  SET_SIDEBAR_OPEN,
  SET_RECORD,
  SET_PRICING_PLAN_ALERT,
} from "./constants";

const initState = {
  open: false,
  message: "",
  hideDuration: "",
  severity: "",
};

const recordInitState = {
  loading: false,
  error: "",
  response: {},
};

const deleteRecordInitState = {
  loading: false,
  error: "",
  response: {},
};

const getRecordInitialState = {
  loading: false,
  error: "",
  response: {},
  record_id: "",
};

const sideBarInitState = {
  open: false,
};

export function sideBarReducer(state = sideBarInitState, { type, payload }) {
  switch (type) {
    case SET_SIDEBAR_OPEN:
      return {
        ...state,
        open: payload,
      };
    default:
      return state;
  }
}

// Records

//get record
export function getRecordReducer(state = getRecordInitialState, action) {
  switch (action.type) {
    case GET_RECORD_BEGIN:
      return { ...state, loading: true };
    case GET_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case GET_RECORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_RECORD_ID:
      return {
        ...state,
        record_id: action.payload,
      };
    case SET_RECORD:
      return {
        ...state,
        response: action.payload,
      };
    default:
      return state;
  }
}

// Update record
export function updateRecordReducer(state = recordInitState, action) {
  switch (action.type) {
    case UPDATE_RECORD_BEGIN:
      return { ...state, loading: true };
    case UPDATE_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case UPDATE_RECORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Delete Record
export function deleteRecordReducer(state = deleteRecordInitState, action) {
  switch (action.type) {
    case DELETE_RECORD_BEGIN:
      return { ...state, loading: true };
    case DELETE_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: {},
      };
    case DELETE_RECORD_FAILURE:
      return {
        ...state,
        loading: false,
        response: "",
        error: action.payload,
      };
    default:
      return state;
  }
}

//Download Credential
export function downloadCredentialReducer(state = recordInitState, action) {
  switch (action.type) {
    case DOWNLOAD_CREDENTIAL_BEGIN:
      return { ...state, loading: true };
    case DOWNLOAD_CREDENTIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case DOWNLOAD_CREDENTIAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Revoke Credential
export function revokeCredentialReducer(state = recordInitState, action) {
  switch (action.type) {
    case REVOKE_CREDENTIAL_BEGIN:
      return { ...state, loading: true };
    case REVOKE_CREDENTIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case REVOKE_CREDENTIAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Share credential

export function shareCredentialReducer(state = recordInitState, action) {
  switch (action.type) {
    case SHARE_CREDENTIAL_BEGIN:
      return { ...state, loading: true };
    case SHARE_CREDENTIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case SHARE_CREDENTIAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

// Get Credential Link

export function getCredentialLinkReducer(state = recordInitState, action) {
  switch (action.type) {
    case GET_CREDENTIAL_LINK_BEGIN:
      return { ...state, loading: true };
    case GET_CREDENTIAL_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case GET_CREDENTIAL_LINK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}

//Notifications
const notificationReducer = (state = initState, action) => {
  switch (action.type) {
    case OPEN_NOTIFICATION:
      return {
        open: true,
        message: action.payload.message,
        hideDuration: action.payload.hideDuration,
        severity: action.payload.severity,
      };
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

export default notificationReducer;

// for now - used only in subject page's draft table
// preview draft record

export function previewDraftRecordReducer(
  state = getRecordInitialState,
  action
) {
  switch (action.type) {
    case PREVIEW_DRAFT_RECORD_BEGIN:
      return { ...state, loading: true };
    case PREVIEW_DRAFT_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
      };
    case PREVIEW_DRAFT_RECORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_RECORD_ID:
      return {
        ...state,
        record_id: action.payload,
      };
    default:
      return state;
  }
}

const logoutInitState = {
  show: false,
};

export const logoutReducer = (state = logoutInitState, action) => {
  switch (action.type) {
    case TOGGLE_LOGOUT_MODAL:
      return {
        ...state,
        show: !state.show,
      };
    case LOGOUT_USER:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};

const analyticsInitState = {
  credentialInfo: {
    loading: false,
    error: "",
    response: {},
  },
  graph: {
    loading: false,
    error: "",
    response: [],
  },
  stats: {
    loading: false,
    error: "",
    response: {},
  },
  verificationLogs: {
    loading: false,
    error: "",
    response: {},
  },
};

export const analyticsReducer = (state = analyticsInitState, action) => {
  switch (action.type) {
    case GET_CREDENTIAL_INFO_BEGIN:
      return {
        ...state,
        credentialInfo: {
          ...state.credentialInfo,
          loading: true,
        },
      };

    case GET_CREDENTIAL_INFO_SUCCESS:
      return {
        ...state,
        credentialInfo: {
          ...state.credentialInfo,
          loading: false,
          response: action.payload,
        },
      };

    case GET_CREDENTIAL_INFO_FAILURE:
      return {
        ...state,
        credentialInfo: {
          ...state.credentialInfo,
          loading: false,
          error: action.payload,
          response: {},
        },
      };

    case GET_VERIFICATION_LOG_BEGIN:
      return {
        ...state,
        verificationLogs: {
          ...state.verificationLogs,
          loading: true,
        },
      };

    case GET_VERIFICATION_LOG_SUCCESS:
      return {
        ...state,
        verificationLogs: {
          ...state.verificationLogs,
          loading: false,
          response: action.payload,
        },
      };

    case GET_VERIFICATION_LOG_FAILURE:
      return {
        ...state,
        verificationLogs: {
          ...state.verificationLogs,
          loading: false,
          error: action.payload,
          response: {},
        },
      };
    case GET_CREDENTIAL_STATS_BEGIN:
      return {
        ...state,
        stats: {
          ...state.stats,
          loading: true,
        },
      };

    case GET_CREDENTIAL_STATS_SUCCESS:
      return {
        ...state,
        stats: {
          ...state.stats,
          loading: false,
          response: action.payload,
        },
      };

    case GET_CREDENTIAL_STATS_FAILURE:
      return {
        ...state,
        stats: {
          ...state.stats,
          loading: false,
          error: action.payload,
          response: {},
        },
      };

    case GET_CREDENTIAL_GRAPH_INFO_BEGIN:
      return {
        ...state,
        graph: {
          ...state.graph,
          loading: true,
        },
      };

    case GET_CREDENTIAL_GRAPH_INFO_SUCCESS:
      return {
        ...state,
        graph: {
          ...state.graph,
          loading: false,
          response: action.payload,
        },
      };

    case GET_CREDENTIAL_GRAPH_INFO_FAILURE:
      return {
        ...state,
        graph: {
          ...state.graph,
          loading: false,
          error: action.payload,
          response: [],
        },
      };
    default:
      return state;
  }
};

// Socket

const socketInitState = {
  newSocket: {},
};

export const socketReducer = (state = socketInitState, action) => {
  switch (action.type) {
    case SET_NEW_SOCKET:
      return {
        ...state,
        newSocket: action.payload,
      };
    default:
      return state;
  }
};

const pricingPlanAlert = {
  show: false,
};

export const pricingPlanAlertReducer = (state = pricingPlanAlert, action) => {
  switch (action.type) {
    case SET_PRICING_PLAN_ALERT:
      return {
        ...state,
        show: action.payload,
      };
    default:
      return state;
  }
};
