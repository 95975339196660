import {
  getUserDetailsReducer,
  headerReducer,
} from "components/layout/Header/header.reducer";
import midBarReducer from "components/layout/Midbar/midbar.reducer";
import { credentialsReducer } from "pages/Credentials/credentials.reducer";
import dashBoardReducer from "pages/DashBoard/dashBoard.reducer";
import ForgetPasswordReducer from "pages/ForgetPassword/ForgetPassword.reducer";
import { holderReducer } from "pages/Holders/holders.reducer";
import credentialsIssuanceReducer from "pages/IssuanceProcess/issuanceProcess.reducer";
import issuerReducer from "pages/Issuers/issuer.reducer";
import loginReducer from "pages/Login/Login.reducer";
import { logsReducer } from "pages/Logs/logs.reducer";
import { pricingReducer } from "pages/Pricing/Pricing.reducer";
import registerReducer from "pages/Register/components/register.reducer";
import resetPasswordReducer from "pages/ResetPassword/resetPassword.reducer";
import {
  changeUserPasswordReducer,
  updateUserProfileReducer,
} from "pages/Settings/settings.reducer";
import {
  addDataFieldReducer,
  createSubjectReducer,
  deleteDataFieldReducer,
  deleteSubjectReducer,
  downloadSampleCsvReducer,
  editDataFieldReducer,
  getAllDatafieldsReducer,
  getDataFieldReducer,
  getThemeReducer,
  subjectStatusReducer,
  updateSubjectReducer,
} from "pages/Subjects/components/CreateSubjectMain/subject.reducer";
import {
  createBadgeReducer,
  updateBadgeReducer,
} from "pages/Badges/components/CreateBadgeMain/badge.reducer";
import {
  addRecordForSubjectReducer,
  getCredentialListForSubjectReducer,
  getSubjectInfoReducer,
  uploadRecordForSubjectReducer,
} from "pages/Subjects/components/SubjectSpecificDetails/subjectDetails.reducer";
import { getBadgeInfoReducer } from "pages/Badges/components/BadgeSpecificDetails/badgeDetails.reducer";
import verifyEmailReducer from "pages/VerifyEmail/verifyEmail.reducer";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import commonReducer, {
  analyticsReducer,
  deleteRecordReducer,
  downloadCredentialReducer,
  getRecordReducer,
  logoutReducer,
  previewDraftRecordReducer,
  pricingPlanAlertReducer,
  revokeCredentialReducer,
  shareCredentialReducer,
  sideBarReducer,
  socketReducer,
  updateRecordReducer,
} from "./commonReducer";

const appReducer = combineReducers({
  common: commonReducer,
  login: loginReducer,
  forgetPassword: ForgetPasswordReducer,
  registerUser: registerReducer,
  resetPassword: resetPasswordReducer,
  dashBoard: dashBoardReducer,
  createBadge: createBadgeReducer,
  createSubject: createSubjectReducer,
  deleteSubject: deleteSubjectReducer,
  addDataField: addDataFieldReducer,
  getAllDatafields: getAllDatafieldsReducer,
  deleteDataField: deleteDataFieldReducer,
  getDataField: getDataFieldReducer,
  editDataField: editDataFieldReducer,
  issuer: issuerReducer,
  midBar: midBarReducer,
  getUser: getUserDetailsReducer,
  credentialIssuanceProcess: credentialsIssuanceReducer,
  addRecordForSubject: addRecordForSubjectReducer,
  uploadRecordForSubject: uploadRecordForSubjectReducer,
  getSubjectInfo: getSubjectInfoReducer,
  getBadgeInfo: getBadgeInfoReducer,
  updateSubject: updateSubjectReducer,
  updateBadge: updateBadgeReducer,
  getTheme: getThemeReducer,
  getListByType: getCredentialListForSubjectReducer,
  getRecord: getRecordReducer,
  updateRecord: updateRecordReducer,
  deleteRecord: deleteRecordReducer,
  revokeCredential: revokeCredentialReducer,
  shareCredential: shareCredentialReducer,
  logout: logoutReducer,
  credentials: credentialsReducer,
  previewDraftRecord: previewDraftRecordReducer,
  downloadRecord: downloadCredentialReducer,
  updateUserProfile: updateUserProfileReducer,
  changeUserPassword: changeUserPasswordReducer,
  subjectStatus: subjectStatusReducer,
  logs: logsReducer,
  header: headerReducer,
  analytics: analyticsReducer,
  socket: socketReducer,
  verifyEmail: verifyEmailReducer,
  downloadSampleCsv: downloadSampleCsvReducer,
  holder: holderReducer,
  sideBar: sideBarReducer,
  pricing: pricingReducer,
  pricingPlanAlert: pricingPlanAlertReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
