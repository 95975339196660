import {
  OPEN_NOTIFICATION,
  CLOSE_NOTIFICATION,
  GET_RECORD_BEGIN,
  GET_RECORD_SUCCESS,
  GET_RECORD_FAILURE,
  SET_RECORD_ID,
  UPDATE_RECORD_BEGIN,
  UPDATE_RECORD_FAILURE,
  UPDATE_RECORD_SUCCESS,
  DELETE_RECORD_BEGIN,
  DELETE_RECORD_FAILURE,
  DELETE_RECORD_SUCCESS,
  DOWNLOAD_CREDENTIAL_BEGIN,
  DOWNLOAD_CREDENTIAL_FAILURE,
  DOWNLOAD_CREDENTIAL_SUCCESS,
  REVOKE_CREDENTIAL_BEGIN,
  REVOKE_CREDENTIAL_FAILURE,
  REVOKE_CREDENTIAL_SUCCESS,
  SHARE_CREDENTIAL_BEGIN,
  SHARE_CREDENTIAL_SUCCESS,
  SHARE_CREDENTIAL_FAILURE,
  GET_CREDENTIAL_LINK_BEGIN,
  GET_CREDENTIAL_LINK_SUCCESS,
  GET_CREDENTIAL_LINK_FAILURE,
  PREVIEW_DRAFT_RECORD_BEGIN,
  PREVIEW_DRAFT_RECORD_FAILURE,
  PREVIEW_DRAFT_RECORD_SUCCESS,
  LOGOUT_USER,
  TOGGLE_LOGOUT_MODAL,
  GET_CREDENTIAL_INFO_BEGIN,
  GET_CREDENTIAL_INFO_SUCCESS,
  GET_CREDENTIAL_INFO_FAILURE,
  GET_VERIFICATION_LOG_BEGIN,
  GET_VERIFICATION_LOG_SUCCESS,
  GET_VERIFICATION_LOG_FAILURE,
  GET_CREDENTIAL_GRAPH_INFO_BEGIN,
  GET_CREDENTIAL_GRAPH_INFO_FAILURE,
  GET_CREDENTIAL_GRAPH_INFO_SUCCESS,
  GET_CREDENTIAL_STATS_BEGIN,
  GET_CREDENTIAL_STATS_SUCCESS,
  GET_CREDENTIAL_STATS_FAILURE,
  SET_NEW_SOCKET,
  SET_SIDEBAR_OPEN,
  SET_RECORD,
  SET_PRICING_PLAN_ALERT,
} from "./constants";

import { http, headers, deleteCookie, downloadFile } from "utils";
import { getSubjectInfo } from "pages/Subjects/components/SubjectSpecificDetails/subjectDetails.action";
import { getIssuerStats } from "pages/Issuers/issuer.action";
import { getSubjectDetails } from "pages/Credentials/credentials.action";

export const toggleSideBar = (payload) => ({
  type: SET_SIDEBAR_OPEN,
  payload: payload,
});

// Notification
export const openNotification = (payload) => ({
  type: OPEN_NOTIFICATION,
  payload: payload,
});

export const closeNotification = () => ({
  type: CLOSE_NOTIFICATION,
});

//Records

//Get Record
const getRecordBegin = () => ({
  type: GET_RECORD_BEGIN,
});

const getRecordSuccess = (payload) => ({
  type: GET_RECORD_SUCCESS,
  payload,
});
const getRecordFailure = (payload) => ({
  type: GET_RECORD_FAILURE,
  payload,
});

export const setRecord = (payload) => ({
  type: SET_RECORD,
  payload,
});

export const getRecord = (record_id, successCB, failureCB) => (dispatch) => {
  dispatch(getRecordBegin());
  http("get", `/user/subject/record/${record_id}`, null, { headers }, true)
    .then((response) => {
      dispatch(getRecordSuccess(response.data.data));
      successCB(response.data.data);
    })
    .catch((error) => {
      dispatch(getRecordFailure(error));
      failureCB(error);
    });
};

export const setRecordId = (payload) => ({
  type: SET_RECORD_ID,
  payload,
});

//update
const updateRecordBegin = () => ({
  type: UPDATE_RECORD_BEGIN,
});

const updateRecordSuccess = (payload) => ({
  type: UPDATE_RECORD_SUCCESS,
  payload,
});
const updateRecordFailure = (payload) => ({
  type: UPDATE_RECORD_FAILURE,
  payload,
});

export const updateRecord =
  (record_id, body, successCB, failureCB) => (dispatch) => {
    dispatch(updateRecordBegin());
    http("put", `/user/subject/record/${record_id}`, body, { headers }, true)
      .then((response) => {
        dispatch(updateRecordSuccess(response.data.data));
        dispatch(
          openNotification({
            message: "Record has been updated successfully!",
          })
        );
        successCB(response);
      })
      .catch((error) => {
        dispatch(updateRecordFailure(error?.response?.data?.message));
        failureCB(error?.response?.data?.message);
      });
  };

//delete
const deleteRecordBegin = () => ({
  type: DELETE_RECORD_BEGIN,
});

const deleteRecordSuccess = (payload) => ({
  type: DELETE_RECORD_SUCCESS,
  payload,
});
const deleteRecordFailure = (payload) => ({
  type: DELETE_RECORD_FAILURE,
  payload,
});

export const deleteRecord =
  (record_id, subject_id, successCB, failureCB) => (dispatch) => {
    dispatch(deleteRecordBegin());
    http("delete", `/user/subject/record/${record_id}`, null, { headers }, true)
      .then((res) => {
        dispatch(deleteRecordSuccess(res.data.data));
        successCB(res.data.data);
        if (subject_id) {
          dispatch(getSubjectInfo(subject_id));
        }
      })
      .catch((error) => {
        dispatch(deleteRecordFailure(error));
        failureCB(error);
        dispatch(
          openNotification({
            message: error.response.data.message,
            severity: "error",
          })
        );
      });
  };

// Download Credential
const downloadCredentialBegin = () => ({
  type: DOWNLOAD_CREDENTIAL_BEGIN,
});

const downloadCredentialSuccess = (payload) => ({
  type: DOWNLOAD_CREDENTIAL_SUCCESS,
  payload,
});
const downloadCredentialFailure = (payload) => ({
  type: DOWNLOAD_CREDENTIAL_FAILURE,
  payload,
});

export const downloadCredential = (cred_id) => (dispatch) => {
  dispatch(downloadCredentialBegin());
  http("get", `/user/credential/${cred_id}/download`, null, { headers }, true)
    .then((response) => {
      dispatch(downloadCredentialSuccess(response.data.data));
      downloadFile(response.data.data.credential);
    })
    .catch((error) => {
      dispatch(downloadCredentialFailure(error?.response?.data?.message));
      dispatch(
        openNotification({
          message: error?.response?.data?.message,
          severity: "error",
        })
      );
    });
};

// Revoke Credential
const revokeCredentialBegin = () => ({
  type: REVOKE_CREDENTIAL_BEGIN,
});

const revokeCredentialSuccess = (payload) => ({
  type: REVOKE_CREDENTIAL_SUCCESS,
  payload,
});
const revokeCredentialFailure = (payload) => ({
  type: REVOKE_CREDENTIAL_FAILURE,
  payload,
});

export const revokeCredential =
  (issuer_id, revokeMessage, successCB, failureCB, subject_id = "") =>
  (dispatch) => {
    dispatch(revokeCredentialBegin());
    http(
      "post",
      `/issuer/${issuer_id}/revoke`,
      revokeMessage,
      { headers },
      true
    )
      .then((response) => {
        dispatch(revokeCredentialSuccess(response.data.data));
        successCB(response.data.data);
        dispatch(getIssuerStats({ issuer_id }));
        if (subject_id) {
          dispatch(getSubjectDetails({ subject_id }));
        }
      })
      .catch((error) => {
        dispatch(revokeCredentialFailure(error?.response?.data?.message));
        failureCB(error?.response?.data?.message);
      });
  };

// Share Credential

const shareCredentialBegin = () => ({
  type: SHARE_CREDENTIAL_BEGIN,
});

const shareCredentialSuccess = (payload) => ({
  type: SHARE_CREDENTIAL_SUCCESS,
  payload,
});
const shareCredentialFailure = (payload) => ({
  type: SHARE_CREDENTIAL_FAILURE,
  payload,
});

export const shareCredential =
  (url, cert_id, body, successCB, failureCB) => (dispatch) => {
    dispatch(shareCredentialBegin());
    http("post", `${url}?credential_id=${cert_id}`, body, { headers }, true)
      .then((response) => {
        dispatch(shareCredentialSuccess(response.data));
        successCB(response.data);
      })
      .catch((error) => {
        dispatch(shareCredentialFailure(error));
        failureCB(error);
      });
  };

// Get Credential Link

const getCredentialLinkBegin = () => ({
  type: GET_CREDENTIAL_LINK_BEGIN,
});

const getCredentialLinkSuccess = (payload) => ({
  type: GET_CREDENTIAL_LINK_SUCCESS,
  payload,
});
const getCredentialLinkFailure = (payload) => ({
  type: GET_CREDENTIAL_LINK_FAILURE,
  payload,
});

export const getCredentialLink =
  (cert_id, successCB, failureCB) => (dispatch) => {
    dispatch(getCredentialLinkBegin());
    http("get", `/user/credential/${cert_id}/view`, null, { headers }, true)
      .then((response) => {
        dispatch(getCredentialLinkSuccess(response.data.data));
        successCB(response.data.data);
      })
      .catch((error) => {
        dispatch(getCredentialLinkFailure(error));
        failureCB(error);
      });
  };

// for now - used only in subject page's draft table
// preview draft record
const previewDraftRecordBegin = () => ({
  type: PREVIEW_DRAFT_RECORD_BEGIN,
});

const previewDraftRecordSuccess = (payload) => ({
  type: PREVIEW_DRAFT_RECORD_SUCCESS,
  payload,
});
const previewDraftRecordFailure = (payload) => ({
  type: PREVIEW_DRAFT_RECORD_FAILURE,
  payload,
});

export const previewDraftRecord =
  ({ record_id, issuer_id }, successCB = () => {}, failureCB = () => {}) =>
  (dispatch) => {
    dispatch(previewDraftRecordBegin());
    let url = `/user/draft/view/${record_id}`;
    if (issuer_id) {
      url = `/user/draft/view/${record_id}?issuer_id=${issuer_id}`;
    }
    http("get", url, null, { headers }, true)
      .then((res) => {
        dispatch(previewDraftRecordSuccess(res.data));
        successCB(res.data);
      })
      .catch((error) => {
        dispatch(previewDraftRecordFailure(error));
        failureCB(error);
      });
  };

export const logOutUser = () => ({
  type: LOGOUT_USER,
});

export const toggleLogoutModal = () => ({
  type: TOGGLE_LOGOUT_MODAL,
});

export const logout = () => (dispatch) => {
  deleteCookie("rememberMe");
  deleteCookie("accessToken");
  deleteCookie("user");
  dispatch(logOutUser());
};

//analytics

//GET INfo
const getCredentialInfoBegin = () => ({
  type: GET_CREDENTIAL_INFO_BEGIN,
});
const getCredentialInfoSuccess = (payload) => ({
  type: GET_CREDENTIAL_INFO_SUCCESS,
  payload,
});

const getCredentialInfoFailure = (payload) => ({
  type: GET_CREDENTIAL_INFO_FAILURE,
  payload,
});

export const getCredentialInfo = (params, failureCB) => (dispatch) => {
  dispatch(getCredentialInfoBegin());
  http(
    "get",
    "/user/credential/subject/details",
    null,
    { headers, params },
    true
  )
    .then((response) => {
      dispatch(getCredentialInfoSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getCredentialInfoFailure());
      failureCB(err.response?.data);
    });
};

//GET Graph
const getCredentialGraphInfoBegin = () => ({
  type: GET_CREDENTIAL_GRAPH_INFO_BEGIN,
});
const getCredentialGraphInfoSuccess = (payload) => ({
  type: GET_CREDENTIAL_GRAPH_INFO_SUCCESS,
  payload,
});

const getCredentialGraphInfoFailure = (payload) => ({
  type: GET_CREDENTIAL_GRAPH_INFO_FAILURE,
  payload,
});

export const getGraphInfo = (params) => (dispatch) => {
  dispatch(getCredentialGraphInfoBegin());
  http("get", "/user/graph/analytics", null, { headers, params }, true)
    .then((response) => {
      dispatch(getCredentialGraphInfoSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getCredentialGraphInfoFailure());
    });
};

//GET verification logs
const getVerificationLogBegin = () => ({
  type: GET_VERIFICATION_LOG_BEGIN,
});
const getVerificationLogSuccess = (payload) => ({
  type: GET_VERIFICATION_LOG_SUCCESS,
  payload,
});

const getVerificationLogFailure = (payload) => ({
  type: GET_VERIFICATION_LOG_FAILURE,
  payload,
});

export const getVerificationLog = (params) => (dispatch) => {
  dispatch(getVerificationLogBegin());
  http("get", "/user/analytics/credential", null, { headers, params }, true)
    .then((response) => {
      dispatch(getVerificationLogSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getVerificationLogFailure());
    });
};

//GET Credentials Stats
const getCredentialStatsBegin = () => ({
  type: GET_CREDENTIAL_STATS_BEGIN,
});
const getCredentialStatsSuccess = (payload) => ({
  type: GET_CREDENTIAL_STATS_SUCCESS,
  payload,
});

const getCredentialStatsFailure = (payload) => ({
  type: GET_CREDENTIAL_STATS_FAILURE,
  payload,
});

export const getCredentialStats = (params) => (dispatch) => {
  dispatch(getCredentialStatsBegin());
  http("get", "/user/credential/stats", null, { headers, params }, true)
    .then((response) => {
      dispatch(getCredentialStatsSuccess(response.data.data));
    })
    .catch((err) => {
      dispatch(getCredentialStatsFailure());
    });
};

// Socket

export const setNewSocket = (payload) => ({
  type: SET_NEW_SOCKET,
  payload,
});

export const setPricingPlanAlert = (payload) => ({
  type: SET_PRICING_PLAN_ALERT,
  payload,
});
